import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [ 'item', 'reset', 'control' ]
  static values = {
    cssKey: String,
    selected: String
  }

  filterItems(e) {
    e.preventDefault();
    this.selectedValue = e.target.dataset[this.cssKeyValue]
  }

  removeSelectedFilter(e) {
    e.preventDefault();
    this.selectedValue = ''
    this.itemTargets.forEach((item) => {
      item.style.display = 'block';
    })
  }

  selectedValueChanged() {
    this.filterItemsBy(this.selectedValue)
    this.idicateSelectedControls()
    this.considerShowingResetButton()
  }

  filterItemsBy(selected) {
    this.itemTargets.forEach((item) => {
      if (item.dataset[this.cssKeyValue].includes(selected)) {
        item.style.display = 'block';
      } else {
        item.style.display = 'none';
      }
    })
  }

  considerShowingResetButton() {
    if (this.selectedValue) {
      this.resetTarget.classList.remove('hidden')
    } else {
      this.resetTarget.classList.add('hidden')
    }
  }

  idicateSelectedControls() {
    this.controlTargets.forEach(control => {
      if (control.dataset[this.cssKeyValue] == this.selectedValue) {
        control.classList.add('active')
      } else {
        control.classList.remove('active')
      }
    })
  }
}